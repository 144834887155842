<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />

    <div class="apipe-cloud-course-container">
      <div class="top-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h2>关于我们</h2>
        </div>
      </div>
      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="">
          <h4>关于我们</h4>
        </div>
      </div>

      <div class="pc-customeValue d-none d-lg-block">
        <div class="top container">
          <div class="main">
            <div class="tit">公司介绍</div>
            <div class="cot">
              深圳优制云工业互联网有限公司，自2018年成立以来，以5500万元注册资本坚实起步，深度践行“中国制造2025”等国家战略，致力于行业数字化转型。我们植根于10年以上的数字化与管道行业的深厚土壤，致力于将创新技术转化为推动行业变革的强大引擎。优制云工业互联网，依托先进的工业互联网与大数据平台，精准布局智能制造、数字企业、智慧园区三大关键领域，为中小微企业提供全方位、一站式的数字化解决方案，成为企业信赖的数字化转型伙伴。
            </div>
            <div class="data">
              <div>
                <span class="num">5500万</span>
                <div class="des">注册资金</div>
              </div>
              <div>
                <span class="num">10年+</span>
                <div class="des">数字化实践</div>
              </div>
              <div>
                <span class="num">40+</span>
                <div class="des">专利&软著</div>
              </div>
            </div>
            <div class="cj">
              <div class="wrap">
                <img
                  src="@/assets/newSite/aboutus/a1.png"
                  style="height: 54px; width: 54px"
                  alt=""
                />
                <div class="de">中国软件企业</div>
              </div>
              <div class="wrap">
                <img
                  src="@/assets/newSite/aboutus/a2.png"
                  style="height: 54px; width: 54px"
                  alt=""
                />
                <div class="de">国家高新技术企业</div>
              </div>
            </div>
          </div>
        </div>
        <div class="fzlc">
          <div class="tit">发展历程</div>
          <img src="@/assets/newSite/aboutus/ex.png" alt="" style="width: -webkit-fill-available;" />
        </div>
        <div class="container">
          <h2 style="padding:60px 0 20px 0;">联系我们</h2>
          <div class="main-cot">
            
            <div>
              <img class="img" src="@/assets/newSite/aboutus/map.png" alt="" />
            </div>
            <div>
              <div>
                <div style="display:flex;">
                  <img src="@/assets/newSite/aboutus/location.png" width="36px" height="36px" alt="" />
                  <h2 style="margin-left:10px;">公司地址</h2>                  
                </div>

                <div style="padding-left:50px;">深圳市南山区朗山路16号华瀚创新园A座10楼</div>
              </div>
              <div style="margin: 30px 0">
                <div style="display:flex;">
                  <img src="@/assets/newSite/aboutus/tel.png" width="36px" height="36px" alt="" />
                  <h2 style="margin-left:10px;">服务热线</h2>
              </div>
                <div style="padding-left:50px;">0755-86016600/13808805396</div>
              </div>
              <div>
                <div style="display:flex;">
                <img src="@/assets/newSite/aboutus/email.png" width="36px" height="36px" alt="" />
                <h2 style="margin-left:10px;">邮箱</h2>
              </div>
                <div style="padding-left:50px;">cs@veiban.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-customeValue d-block d-lg-none">
        <div class="container">
          <div class="main-cot">
            <div style="margin-bottom: 30px">
              <img src="@/assets/newSite/aboutus/map.png" alt="" />
            </div>
            <div>
              <div>
                <h3>公司地址</h3>
                <div>深圳市南山区朗山路16号华瀚创新园A座9楼</div>
              </div>
              <div style="margin: 30px 0">
                <h3>服务热线</h3>
                <div>13808805396</div>
              </div>
              <div>
                <h3>邮箱</h3>
                <div>cs@veiban.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NewFooter />
      <MobileFooter class="d-block d-lg-none" />
    </div>
  </div>
</template>
            
<script>
import { Newslist } from "@/api/fetch";
import Qs from "qs";
export default {
  name: "hardware",
  data() {
    return {
      cur: 1,
      activeName: "1",
    };
  },
 created(){

 
 },
  mounted() {
    const type = this.$route.query.type || ''
    this.getScroll(type)
  },

  methods: {
     getScroll(type){
      console.log(type)
      if(type == 's'){
        document.documentElement.scrollTop = 500
      }else if(type == 'f'){
        document.documentElement.scrollTop = 1300
      }else if(type == 'l'){
        document.documentElement.scrollTop = 2000
      }else{
        // document.documentElement.scrollTop = 0
      }
     }
  },
};
</script>
            
<style lang="less"  scoped>
.apipe-cloud-course-container {
  .top-banner {
    height: 600px;
    background-image: url("../../assets/newSite/aboutus/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 16px;
      }
    }
  }
  .mobile-banner {
    height: 200px;
    background-image: url("../../assets/newSite/home/p4.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 12px;
      }
      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .pc-customeValue {
    padding: 60px 0;
    .top {
      background-image: url("../../assets/newSite/aboutus/bg1.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 730px;
      position: relative;
      .main {
        text-align: left;
        position: absolute;
        right: 120px;
        top: 120px;
        width: 650px;
        height: 400px;
        // border: 1px solid red;
        .tit {
          margin-bottom: 25px;
          line-height: 40px;
          font-family: Source Han Sans CN;
          color: #000000;
          font-size: 40px;
        }
        .cot {
          line-height: 30px;
          font-family: Source Han Sans CN;
          font-weight: 200;
          color: #333333;
          font-size: 16px;
        }
        .data {
          display: flex;
          justify-content: space-around;
          margin-top: 50px;

          .num {
            font-family: Source Han Sans CN;
            font-weight: 700;
            color: #1484ff;
            font-size: 42px;
          }
          .des {
            text-align: center;
            font-family: Source Han Sans CN;
            font-weight: 200;
            color: #333333;
            font-size: 18px;
          }
        }
        .cj {
          display: flex;
          justify-content: space-evenly;
          margin-top: 30px;
          text-align: center;
          .wrap {
            text-align: center;
          }
          .de {
            margin-top: 10px;
          }
        }
      }
    }
    .fzlc {
      padding: 60px 0;
      background:#f6faff;
      .tit{
     margin-bottom: 30px;
      font-family: Source Han Sans CN;
      color: #333333;
      font-size: 36px;
  
      }
    }

    .main-cot {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      .img {
        width: 800px;
        height: 460px;
      }
    }
  }
  .mobile-customeValue {
    .main-cot {
      padding: 20px;
      text-align: left;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
            